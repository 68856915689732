/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Button, Image, ColumnCover, ColumnDiv, PriceWrap } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-1b7odf6 --full pb--30 pt--30" name={"wstęp"} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/76/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/76/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/76/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/76/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/76/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/76/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/76/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/76/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="pb--60">
              
              <Title className="title-box w--400 title-box--invert ls--002" content={"Pańska 18"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--invert mt--02" content={"Warszawa, Miasto Warszawa"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"informacje"} style={{"backgroundColor":"var(--color-variable-1)"}}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--invert" content={"“Cytat wymusza wstawienie krótszej wersji tekstu potocznego.”  "}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Subtitle className="subtitle-box subtitle-box--invert" content={"Czesne i zapisy 21.1."}>
              </Subtitle>

              <Text className="text-box text-box--invert" style={{"maxWidth":430}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--cColor2 fs--24" href={"#cennik"} content={"Dowiedz się więcej"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"informacje-2"}>
          
          <ColumnWrap className="column__flex el--3" style={{"gridColumnGap":"8%"}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--36" content={"Nigdy nie więcej niż 8 dzieci w grupie"}>
              </Title>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Ponad 10 lat doświadczenia w pracy z dziećmi"}>
              </Title>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Doświadczenie<br>wielojęzyczności"}>
              </Title>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"informacje-3"} layout={"l30"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/76/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Title className="title-box fs--48 title-box--invert" content={"Działania kreatywne"}>
                    </Title>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/76/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Title className="title-box fs--48 title-box--invert" content={"Nowoczesne wyposażenie"}>
                    </Title>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"cennik"} style={{"backgroundColor":"var(--color-variable-2)"}}>
          
          <ColumnWrap className="column__flex el--3" columns={"3"}>
            
            <ColumnWrapper className="pr--40" style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--invert" content={"Czesne i zapisy"}>
              </Title>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--invert lh--14" content={"— Czesne jest płatne w pierwszym dniu"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--invert lh--14" content={"— Czesne jest płatne w pierwszym dniu lub z góry po uzgodnieniu."}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--invert lh--14" content={"— Czesne jest płatne w pierwszym dniu"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--invert pb--20" content={"Program poranny"}>
              </Subtitle>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Obecność 1 dzień w tygodniu"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"33,— zł"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Obecność 2 dzień w tygodniu"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"33,— zł"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Obecność 3 dzień w tygodniu"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"33,— zł"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Obecność 1 dzień w tygodniu"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"33,— zł"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Obecność 1 dzień w tygodniu"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"33,— zł"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Obecność 1 dzień w tygodniu"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"33,— zł"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--invert pb--20" content={"Program całodniowy"}>
              </Subtitle>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Obecność 1 dzień w tygodniu"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"33,— zł"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Obecność 1 dzień w tygodniu"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"33,— zł"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Obecność 1 dzień w tygodniu"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"33,— zł"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Obecność 1 dzień w tygodniu"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"33,— zł"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Obecność 1 dzień w tygodniu"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"33,— zł"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Obecność 1 dzień w tygodniu"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"33,— zł"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"nasz-team"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper className="mb--10">
              
              <Image className="--shape5" alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":200}} srcSet={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"Mgr. Alicja Wrona"}>
              </Subtitle>

              <Text className="text-box mt--02" content={"Wychowawca"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--10">
              
              <Image className="--shape5" alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":200}} srcSet={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"Mgr. Alicja Wrona"}>
              </Subtitle>

              <Text className="text-box mt--02" content={"Wychowawca"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--10">
              
              <Image className="--shape5" alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":200}} srcSet={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"Mgr. Alicja Wrona"}>
              </Subtitle>

              <Text className="text-box mt--02" content={"Wychowawca"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--10">
              
              <Image className="--shape5" alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":200}} srcSet={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"Mgr. Alicja Wrona"}>
              </Subtitle>

              <Text className="text-box mt--02" content={"Wychowawca"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--10">
              
              <Image className="--shape5" alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":200}} srcSet={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"Mgr. Alicja Wrona"}>
              </Subtitle>

              <Text className="text-box mt--02" content={"Wychowawca"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--10">
              
              <Image className="--shape5" alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":200}} srcSet={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"Mgr. Alicja Wrona"}>
              </Subtitle>

              <Text className="text-box mt--02" content={"Wychowawca"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--10">
              
              <Image className="--shape5" alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":200}} srcSet={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"Mgr. Alicja Wrona"}>
              </Subtitle>

              <Text className="text-box mt--02" content={"Wychowawca"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--10">
              
              <Image className="--shape5" alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":200}} srcSet={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"Mgr. Alicja Wrona"}>
              </Subtitle>

              <Text className="text-box mt--02" content={"Wychowawca"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"galeria"} layout={"l12"}>
          
          <ColumnWrap className="column__flex --full --center el--4 pl--02 pr--0" columns={"4"}>
            
            <ColumnWrapper className="pb--02 pr--02">
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/76/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--02 pr--02">
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/76/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--02 pr--02">
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/76/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--02 pr--02">
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/76/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--02 pr--02">
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/76/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--02 pr--02">
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/76/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--02 pr--02">
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/76/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--02 pr--02">
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/76/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--50" name={"stopka"}>
          
          <ColumnWrap className="column__flex el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Przedszkole"}>
              </Title>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"<a href=\"https://saywebpage.com\">Tworzenie stron</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Kontakt"}>
              </Title>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"+48 797 811 2X0<br>info@twoje-stronyy.com<br>Marszałkowska 153<br> Warszawa 02-077<br>Polska"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Godziny otwarcia"}>
              </Title>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"Poniedziałek - Piątek:"}>
              </Text>

              <Subtitle className="subtitle-box mt--0" content={"6:00 - 18:00"}>
              </Subtitle>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"Weekend:"}>
              </Text>

              <Subtitle className="subtitle-box mt--0" content={"6:00 - 18:00"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Title className="title-box" content={"Nawigacja"}>
              </Title>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"<a href='#[[T73SecName3]]'>Wstęp</a>"}>
              </Text>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"<a href='#[[T73SecName6]]'>Nasz team</a>"}>
              </Text>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"<a href='#[[T73SecName5]]'>Czesne i zapisy</a>"}>
              </Text>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"<a href='#[[T73SecName7]]'>Galeria</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}